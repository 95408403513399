import React from "react";
import { getLocalStorage } from "../../Helper/LocalStorage";
import { Avatar, Badge, Flex, Paper, Text } from "@mantine/core";
import {
    IconCategory,
    IconMailFilled,
    IconMap2,
    IconUser,
} from "@tabler/icons-react";
import styles from "./Directory.module.css";
export default function ViewMember() {
    const member = getLocalStorage("member");
    return (
        <Paper ta={"center"} p={50}>
            <Avatar size={250} mx={"auto"} src={member.profile} />
            <Text mt={20} tt={"capitalize"} fz={35} size="xl" fw={700}>
                {member.firstName} {member.lastName}
            </Text>
            <Flex align={"center"} justify={"center"} gap={10}>
                <Badge
                    className={styles.email_badge}
                    onClick={() => window.open(`mailto:${member.email}`)}
                    leftSection={<IconMailFilled size={18} />}
                    variant="gradient"
                    gradient={{ from: "cyan", to: "green", deg: 90 }}
                    mt={20}
                    tt={"lowercase"}
                    size="xl"
                    fw={500}
                >
                    {member.email}
                </Badge>
                <Badge
                    leftSection={<IconCategory size={18} />}
                    variant="gradient"
                    gradient={{ from: "cyan", to: "green", deg: 90 }}
                    mt={20}
                    tt={"capitalize"}
                    size="xl"
                    fw={500}
                >
                    {member.type}
                </Badge>
                <Badge
                    leftSection={<IconUser size={18} />}
                    variant="gradient"
                    gradient={{ from: "cyan", to: "green", deg: 90 }}
                    mt={20}
                    tt={"capitalize"}
                    size="xl"
                    fw={500}
                >
                    {member.type === "professional"
                        ? member.profession
                        : member.course}
                </Badge>
                <Badge
                    leftSection={<IconMap2 size={18} />}
                    variant="gradient"
                    gradient={{ from: "cyan", to: "green", deg: 90 }}
                    mt={20}
                    tt={"capitalize"}
                    size="xl"
                    fw={500}
                >
                    {member.country}
                </Badge>
            </Flex>

            <Text mt={20} tt={"capitalize"} size="lg" fw={"normal"}>
                {member.bio}
            </Text>
        </Paper>
    );
}
