import {
    Badge,
    Button,
    Card,
    Container,
    Divider,
    Flex,
    Grid,
    Group,
    Image,
    Input,
    Paper,
    Select,
    SimpleGrid,
    Text,
    useMantineTheme,
} from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import moment from "moment/moment";
import { useMediaQuery } from "@mantine/hooks";

export default function Vlogs() {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [categoryFilter, setCategoryFilter] = useState("");
    const [sortOrder, setSortOrder] = useState("newest");
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
    useEffect(() => {
        getVlogs();
    }, []);

    //   useEffect(() => {
    //     applyFilters();
    //   }, [searchTerm, categoryFilter, sortOrder, data]);

    const getVlogs = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/getvlogs`
            );
            setData(response.data);
            setFilteredData(response.data);
        } catch (err) {
            console.error("Failed to load vlogs", err);
        }
    };

    // Function to handle input changes
    const applyFilters = async () => {
        try {
            // Construct the URL based on the current filter and sort states
            const response = await axios.get(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/searchsortfiltervlogs`,
                {
                    params: {
                        search: searchTerm,
                        category: categoryFilter,
                        sort: sortOrder,
                    },
                }
            );
            if (response.data.length === 0) {
                toast.error("No videos found");
                return;
            }
            // Update the data and filteredData states
            setData(response.data);
            setFilteredData(response.data);
        } catch (error) {
            console.error("Error fetching vlogs:", error);
        }
    };

    return (
        <>
            <Container size="xl" my="lg">
                {/* Search, Sort, and Filter Section */}
                <Group
                    position="apart"
                    mb="md"
                    spacing="md"
                    direction="row"
                    style={{ flexWrap: "wrap" }}
                >
                    <Input
                        icon={<IconSearch />}
                        placeholder="Search by title"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ width: "100%", maxWidth: "300px" }}
                    />
                    <Select
                        placeholder="Sort by"
                        data={[
                            { value: "newest", label: "Newest" },
                            { value: "oldest", label: "Oldest" },
                        ]}
                        value={sortOrder}
                        onChange={setSortOrder}
                        style={{ width: "100%", maxWidth: "200px" }}
                    />
                    <Select
                        placeholder="Filter by category"
                        data={[
                            { value: "", label: "All Categories" },
                            { value: "P&C", label: "P&C" },
                            { value: "PINES 10Qs", label: "PINES 10Qs" },
                            {
                                value: "Conference Recordings",
                                label: "Conference Recordings",
                            },
                            { value: "Other Videos", label: "Other Videos" },
                        ]}
                        value={categoryFilter}
                        onChange={setCategoryFilter}
                        style={{ width: "100%", maxWidth: "200px" }}
                    />
                    <Button
                        variant="gradient"
                        gradient={{ from: "cyan", to: "green", deg: 90 }}
                        onClick={applyFilters}
                        style={{ width: "100%", maxWidth: "120px" }}
                    >
                        Apply
                    </Button>
                </Group>
                <Divider my="sm" />
            </Container>

            {/* Video Resources Grid */}
            <Grid bg={"white"}>
                {filteredData.map(
                    ({
                        _id,
                        title,
                        thumbnail,
                        author,
                        category,
                        createdAt,
                    }) => (
                        <Grid.Col span={isMobile ? 12 : 4}>
                            <Paper
                                mx={"sm"}
                                radius={"lg"}
                                p="lg"
                                key={_id}
                                onClick={() =>
                                    window.open(
                                        `/vlog_view?id=${_id}`,
                                        "_blank"
                                    )
                                }
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <Image
                                   style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}

                                
                                    radius={"xl"}
                                    alt={title}
                                    src={thumbnail}
                                />
                                <Text mx={"lg"} mt={"md"} fw={"bold"}>
                                    {title}
                                </Text>
                                <Text mx={"lg"} mb={"md"} c={"dimmed"}>
                                    {author}
                                </Text>
                                <Flex>
                                    <Badge
                                        variant="gradient"
                                        gradient={{
                                            from: "cyan",
                                            to: "green",
                                            deg: 90,
                                        }}
                                        mx={"lg"}
                                        size="sm"
                                    >
                                        {category}
                                    </Badge>
                                    <Text
                                        size="sm"
                                        ml={"auto"}
                                        fw={"bold"}
                                        c={"dimmed"}
                                    >
                                        {moment(createdAt).format(
                                            "MMMM Do YYYY"
                                        )}
                                    </Text>
                                </Flex>
                            </Paper>
                        </Grid.Col>
                    )
                )}
            </Grid>
        </>
    );
}
