import React, { useEffect, useState } from "react";
import {
    Card,
    Image,
    Text,
    Title,
    Stack,
    Container,
    Chip,
    Badge,
} from "@mantine/core";
import { IconCalendarMonth, IconCategory, IconUser } from "@tabler/icons-react";
import axios from "axios";
import { notifications } from "@mantine/notifications";
// import LoaderAnimation from "../../loader";
import moment from "moment";
import LoaderAnimation from "../loader";
import { useSearchParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";

const View = () => {
    const [content, setContent] = useState({
        title: "",
        category: "",
        images: [],
        slideCaptions: "",
        description: "",
        author: "",
        date: "",
    });
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    useEffect(() => {
        if (!id) return; // Ensure id is available
        getBlog();
    }, [id]);

    const getBlog = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/blog/${id}`
            );
            const data = response.data;
            console.log(data);
            let imgData = [];
            data.images.map(async (item) => {
                const img = await axios.get(
                    `${process.env.REACT_APP_BE_URI}/api/v1/common/blogimages/${item}`,
                    {
                        responseType: "blob",
                    }
                );
                console.log("this is img: ", URL.createObjectURL(img.data));
                setContent((prev) => ({
                    ...prev,
                    image: [...prev.images, URL.createObjectURL(img.data)],
                }));
                imgData.push(URL.createObjectURL(img.data));
            });
            console.log(imgData);
            setContent({
                title: data.title,
                category: data.category,
                images: imgData, // Ensure this is returned by your API
                slideCaptions: data.slideCaptions,
                description: data.content,
                author: data.author,
                date: data.date,
            });
            setLoading(false); // Set loading to false once data is fetched
        } catch (err) {
            console.log(err);
            notifications.show({
                title: "Error",
                message: err.response?.data?.message || "An error occurred",
                color: "red",
                autoClose: 2000,
            });
            setLoading(false); // Ensure loading is set to false even on error
        }
    };

    if (loading) {
        return <LoaderAnimation />; // Show a loading message while fetching data
    }
    console.log("this is content: ", content);

    return (
        <Container size="lg" my="md">
            <Card padding="lg" radius="md">
                <Stack spacing="md">
                    <div className=" flex">
                        <Title
                            style={{ textTransform: "capitalize" }}
                            order={2}
                        >
                            {content.title}
                        </Title>
                        {/* <Chip variant="filled" defaultChecked icon={<IconCategory size={15} />}  ml={"auto"}>{category}</Chip> */}
                        <div style={{ marginTop: "15px" }}>
                            <Badge
                                size="lg"
                                mr={5}
                                variant="gradient"
                                gradient={{
                                    from: "blue",
                                    to: "green",
                                    deg: 90,
                                }}
                                leftSection={<IconCategory size={15} />}
                            >
                                {content.category}
                            </Badge>
                            <Badge
                                size="lg"
                                mx={5}
                                style={{ textTransform: "capitalize" }}
                                variant="gradient"
                                gradient={{
                                    from: "blue",
                                    to: "green",
                                    deg: 90,
                                }}
                                leftSection={<IconUser size={15} />}
                            >
                                {content.author}
                            </Badge>
                        </div>
                    </div>
                    {/* <Image
            src={content.image}
            alt={content.title}
            style={{ objectFit: "cover", height: "100px", width: "100%" }}
            fit="cover"
            radius="md"
          /> */}
                    {console.log(content?.images)}
                    {/* <Carousel withIndicators height={200}>
            {content.images?.map((item, index) => (
              <Carousel.Slide
                withIndicators
                height={200}
                slideSize={{ base: "100%", sm: "50%", md: "33.333333%" }}
                slideGap={{ base: 0, sm: "md" }}
                loop
                align="start"
              >
                <Card shadow="sm" padding="xl" component="a" target="_blank">
                  {console.log(item)}
                  <Card.Section>
                    <Image src={item} h={160} alt="No way!" />
                  </Card.Section>

                  <Text fw={500} size="lg" mt="md">
                    {content.slideCaptions[index]}
                  </Text>
                </Card>
              </Carousel.Slide>
            ))}
          </Carousel> */}
                    {/* IMAGE SLIDES SECTION START*/}
                    <div style={{ width: "100%", marginTop: "10px" }}>
                        <Carousel>
                            {content.images?.map((item, index) => (
                                <div key={index}>
                                    <img style={{ width: "100%" }} src={item} />
                                    <p>{content.slideCaptions[index]}</p>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    {/* IMAGE SLIDES SECTION END  */}

                    <Text size="md">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: content.description,
                            }}
                        />
                    </Text>
                    <Badge
                        size="lg"
                        mx={5}
                        variant="gradient"
                        gradient={{ from: "blue", to: "green", deg: 90 }}
                        leftSection={<IconCalendarMonth size={15} />}
                    >
                        {moment(content.date).format("dddd, MMMM Do, YYYY")}
                    </Badge>
                </Stack>
            </Card>
        </Container>
    );
};

export default View;
