import * as React from 'react';
import { getLocalStorage } from '../../Helper/LocalStorage'
import { Avatar, Box, ThemeIcon, UnstyledButton, Text, Group, useMantineTheme } from '@mantine/core';
import { Link } from 'react-router-dom'
import { useState } from 'react';
import classes from './Sidebar.module.css';
import {IconHome, IconChevronRight, IconUsersGroup, IconFileDollar, IconHelp, IconCurrencyDollar, IconArticle, IconVideo} from '@tabler/icons-react';



const Sidebar = () => {
    const [active, setActive] = useState('Dashboard');
    const user = getLocalStorage('user');
    const profilePic = getLocalStorage('profilePic');
    const sidebarOptions = [
        {display: "Dashboard", link: "dashboard", icon: <IconHome/>, color: "blue"},
        {display: "Membership Directory", link: "directory", icon: <IconUsersGroup/>, color: "green"},
        {display: "Blogs", link: "blogs", icon: <IconArticle/>, color: "yellow"},
        {display: "Video Resources", link: "vlogs", icon: <IconVideo/>, color: "purple"},
        {display: "Subscription", link: "subscription", icon: <IconCurrencyDollar/>, color: "violet"},
        {display: "Order List", link: "orders", icon: <IconFileDollar/>, color: "pink"},
        {display: "FAQs", link: "faqs", icon: <IconHelp/>, color: "cyan"},
    ]
   
    const navItems = sidebarOptions.map((item)=>
        <Link
            className={classes.link}
            data-active={item.link === active || undefined}
            to={"/"+item.link}
            key={item.link}
            onClick={(event) => {
                setActive(item.link);
            }}
        >
            <ThemeIcon variant="light" size={40} color={item.color}>
                {item.icon}
            </ThemeIcon>
            <Box ml="md">{item.display}</Box>
        </Link>
        );

    return (
        <nav className={classes.navbar}>
            <div className={classes.navbarMain}>
                {navItems}
            </div>
            <div className={classes.footer}>
                <Link 
                to="/profile" 
                className={classes.user} 
                onClick={()=>setActive("profile")}
                data-active={"profile" === active || undefined}>
                <UnstyledButton >
                    <Group>
                        <Avatar
                            src={profilePic}
                            radius="xl"
                        />

                        <div style={{ flex: 1 }}>
                            <Text size="sm" fw={500}>
                                {user?.firstName + " " + user?.lastName}
                            </Text>

                            <Text c="dimmed" size="xs">
                                {user?.email}
                            </Text>
                        </div>

                        <IconChevronRight style={{ width: "1.2rem", height: "1.2rem" }} stroke={1.5} />
                    </Group>
                </UnstyledButton>
                </Link>
            </div>
        </nav>
    );
}

export default Sidebar;