import {
  Badge,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Group,
  Image,
  Select,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import axios from "axios";
// import Link from "next/link";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
// import SortFilter from "./sortFilter";
import toast from "react-hot-toast";
import SearchSortFilter from "./sortFilter";
import { useMediaQuery } from "rsuite/esm/useMediaQuery/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { truncateTo100Words } from "../../Helper/TruncateText";
import { IconCalendarMonth, IconCategory } from "@tabler/icons-react";
import moment from "moment";
export default function Blogs() {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  //   const router = useRouter();
  const [data, setData] = useState([]);
  const [images, setImages] = useState([]); // Store images in an object with blog ID or index as keys

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async () => {
    axios
      .get(`${process.env.REACT_APP_BE_URI}/api/v1/common/getallblogs/normal`)
      .then((res) => {
        setData(res.data);
        res.data.map((blog) => {
          axios
            .get(
              `${process.env.REACT_APP_BE_URI}/api/v1/common/blogimages/${blog.images[0]}`,
              {
                responseType: "blob",
              }
            )
            .then((res) => {
              const img = URL.createObjectURL(res.data);
              setImages((prev) => ({ ...prev, [blog._id]: img }));
            })
            .catch((err) => {
              console.log(err);
              notifications.show({
                title: "Error",
                message: err.response?.data?.message || "Failed to load images",
                color: "red",
                autoClose: 2000,
              });
            });
        });
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const sortfilter = (data) => {
    axios
      .get(
        `${process.env.REACT_APP_BE_URI}/api/v1/common/searchsortfilterblogs?sort=${data.sort}&category=${data.category}&search=${data.search}`
      )
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data.length === 0) {
          toast.error("No blogs found");
        } else {
          setData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
  return (
    <div>
      <div>
        <h1
          style={{
            fontSize: "1.875rem", // equivalent to text-3xl
            fontWeight: 600, // equivalent to font-semibold
            marginTop: "1.25rem", // equivalent to mt-5
            marginBottom: "1rem", // equivalent to mb-4
            marginLeft: "2.5rem", // equivalent to mx-10 (left and right margin)
            marginRight: "2.5rem",
          }}
        >
          Recent Blogs
        </h1>
        {/* search, sort, filter functionality here */}
        <SearchSortFilter onFilterChange={sortfilter} />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isMobile[0]
              ? "1fr"
              : "repeat(2, minmax(0, 1fr))",
            gap: "20px", // Adjust gap between cards
            marginLeft: isMobile[0] ? "10px" : "2.5rem", // Reduce margin on smaller screens
            marginRight: isMobile[0] ? "10px" : "2.5rem",
          }}
        >
          {data.map((blog, i) => (
            <Card
              key={blog.id}
              style={{ position: "relative" }}
              shadow="sm"
              padding="lg"
              radius="md"
              withBorder
              mx={20}
              my={20}
            >
              <Card.Section>
                <Image
                  src={images[blog._id]} // Use placeholder if image is not found
                  height={280}
                  alt={blog.title}
                />
              </Card.Section>
              <Group position="apart" mt="md" mb="xs">
                <Text style={{ textTransform: "capitalize" }} fw={500}>
                  {blog.title}
                </Text>
              </Group>
              <div style={{ display: "flex" }}>
                <Badge
                  size="md"
                  leftSection={<IconCategory size={18} />}
                  mx={5}
                  color="pink"
                >
                  {blog.category}
                </Badge>
                <Badge
                  size="md"
                  leftSection={<IconCalendarMonth size={18} />}
                  mx={5}
                  color="purple"
                >
                  {moment(blog.date).format("MMMM Do YYYY")}
                </Badge>
              </div>
              <Text size="sm" color="dimmed">
                <div
                  style={{
                    marginBottom: "2.5rem", // equivalent to mb-10
                  }}
                  dangerouslySetInnerHTML={{
                    __html: truncateTo100Words(blog.content),
                  }}
                />
              </Text>

              <Button
                onClick={() =>
                  window.open(`/blog_view?id=${blog._id}`, "_blank")
                }
                style={{
                  position: "absolute",
                  bottom: "10px",
                  left: "10%",
                  width: "80%",
                  margin: "auto",
                }}
                autoContrast
                color="#A3E5E4"
                mt="md"
                radius="md"
              >
                Read
              </Button>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}
