import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ApiContextWrapper } from "./utils/ApiContext";
import "@mantine/core/styles.css";
import { createTheme, MantineProvider } from "@mantine/core";
import HomePage from "./Pages/Home/HomePage";
import LoginPage from "./Pages/Login/LoginPage";
import Signup from "./Pages/Signup/Signup";
import StudentSignUp from "./Pages/Signup/StudentSignUp";
import ProfessionalSignUp from "./Pages/Signup/ProfessionalSignUp";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Directory from "./Pages/Directory/Directory";
import Faqs from "./Pages/Faqs/Faqs";
import NewDashboard from "./Pages/Dashboard/newDashboard";
import PaymentSuccessPage from "./Pages/Stripe/paySuccess";
import Pay from "./Pages/Stripe/pay";
import { Toaster } from "react-hot-toast";
import UserDetail from "./Pages/Dashboard/userDetail";
import PaymentPage from "./Pages/Payment/PaymentPage";
import SampleHome from "./Pages/Home/SampleHome";
import Profile from "./Pages/Profile/Profile";
import SubscriptionRenewalPage from "./Pages/Payment/SubscriptionPayment";
import SubscriptionDetails from "./Pages/Subscription/subscription";
import Blogs from "./Pages/Blogs/blogs";
import View from "./Pages/Blogs/view";
import { OrderList } from "./Pages/OrderList/orderList";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Vlogs from "./Pages/Vlogs/vlogs";
import VlogView from "./Pages/Vlogs/view";
import EventView from "./Pages/Events/view";
import ViewMember from "./Pages/Directory/View";
import ResetPasswordPage from "./Pages/ResetPassword/resetPassword";
import ForgotPasswordPage from "./Pages/ForgotPassword/forgotPassword";

const theme = createTheme({
    colors: {
        primary: ["#47c41a"],
        secondary: ["#039cc8"],
    },
    breakpoints: {
        xs: "30em",
        sm: "48em",
        md: "64em",
        lg: "74em",
        xl: "90em",
    },
});

function App() {
    return (
        <ApiContextWrapper>
            <MantineProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<LoginPage />}></Route>
                        <Route path="/signup" element={<Signup />}></Route>
                        <Route
                            path="/student-signup"
                            element={<StudentSignUp />}
                        ></Route>
                        <Route
                            path="/professional-signup"
                            element={<ProfessionalSignUp />}
                        ></Route>
                        <Route
                            path="/newdashboard"
                            element={<NewDashboard />}
                        ></Route>
                        {/* STRIPE ROUTES HERE */}
                        <Route path="/stripe" element={<Pay />}></Route>
                        <Route
                            path="/paySuccess"
                            element={<PaymentSuccessPage />}
                        ></Route>
                        {/* HANDLING AFTER APPROVAL */}

                        <Route
                            path="/subscriptionpayment"
                            element={<PaymentPage />}
                        ></Route>
                        <Route
                            path="/renewalpayment"
                            element={<SubscriptionRenewalPage />}
                        ></Route>
                        <Route
                            path="/forgotpassword"
                            element={<ForgotPasswordPage />}
                        ></Route>
                        <Route
                            path="/resetpassword/:type/:token"
                            element={<ResetPasswordPage />}
                        ></Route>
                        <Route path="/" element={<HomePage />}>
                            <Route
                                path="/dashboard"
                                element={<Dashboard />}
                            ></Route>
                            <Route
                                path="/directory"
                                element={<Directory />}
                            ></Route>
                            <Route
                                path="/view_member"
                                element={<ViewMember />}
                            ></Route>
                            <Route
                                path="/subscription"
                                element={<SubscriptionDetails />}
                            ></Route>
                            <Route
                                path="/orders"
                                element={<OrderList />}
                            ></Route>
                            <Route path="/faqs" element={<Faqs />}></Route>
                            <Route path="/blogs" element={<Blogs />}></Route>
                            <Route path="/blog_view" element={<View />}></Route>
                            <Route path="/vlogs" element={<Vlogs />}></Route>
                            <Route
                                path="/vlog_view"
                                element={<VlogView />}
                            ></Route>
                            <Route
                                path="/event_view"
                                element={<EventView />}
                            ></Route>
                            <Route
                                path="/profile"
                                element={<Profile />}
                            ></Route>
                        </Route>
                    </Routes>
                    <Toaster />
                </BrowserRouter>
            </MantineProvider>
        </ApiContextWrapper>
    );
}

export default App;
