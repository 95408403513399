import * as React from 'react';
import { removeLocalStorage } from '../../Helper/LocalStorage'
import { Link } from 'react-router-dom'
import { Autocomplete, Tooltip, Box, UnstyledButton, Image } from '@mantine/core';
import { IconLogout, IconSearch } from '@tabler/icons-react';
import classes from './Navbar.module.css';
import logo from './logo-color.png'

function Navbar(props) {
    const userLogout = (e) => {
        removeLocalStorage("user");
        removeLocalStorage("profilePic");
        window.location.replace("/login")
    };

    const searchStyles = {
        input: {
            borderRadius: "50px",
            background: "var(--mantine-color-gray-4)",
            fontWeight: "500"
        }
      };

    return (
        <Box>
            <div className={classes.nav}>
                <div className={classes.controls}>
                    <props.toggles></props.toggles>
                    <Link to="/dashboard"><Image h={40} w={"auto"} src={logo} alt='pines'></Image></Link>
                </div>
                <div className={classes.controls}>
                    <Autocomplete
                        className={classes.searchbox}
                        placeholder="Search"
                        leftSection={<IconSearch style={{ width: "1.5rem", height: "1.5rem" }} stroke={3} color='black'/>}
                        visibleFrom="xs"
                        styles={searchStyles}
                    />
                    <Tooltip label="Log out">
                        <UnstyledButton className={classes.link} onClick={userLogout}>
                            <IconLogout color='var(--mantine-color-blue-8)' />
                        </UnstyledButton>
                    </Tooltip>
                </div>
            </div>
        </Box>

    );
}
export default Navbar;