import { Center, Loader } from "@mantine/core";

export default function LoaderAnimation() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="flex flex-col items-center">
        <svg
          className="animate-spin h-10 w-10 text-green-500 mb-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zM12 24c-4.627 0-8.627-3.132-9.799-7.428L0 17.799C1.502 22.057 6.227 24 12 24v-4z"
          />
        </svg>
        <p className="text-gray-700 text-lg">Loading...</p>
      </div>
    </div>
    // <Center maw={400} h={100}>
    //   <Loader className="mx-auto" color="lime" />
    // </Center>
  );
}
