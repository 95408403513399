import React, { useEffect, useState } from "react";
import {
    Card,
    Image,
    Text,
    Title,
    Stack,
    Container,
    Chip,
    Badge,
} from "@mantine/core";
import { IconCalendarMonth, IconCategory, IconUser } from "@tabler/icons-react";
import axios from "axios";
// import LoaderAnimation from "../..";
import { Carousel } from "react-responsive-carousel";
import { useSearchParams } from "react-router-dom";
import LoaderAnimation from "../loader";
import moment from "moment";
import toast from "react-hot-toast";

const EventView = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [content, setContent] = useState({
        title: "",
        category: "",
        images: "",
        description: "",
        mode: "",
        link: "",
        location: "",
        date: "",
        time: "",
        postDate: "",
        author: "",
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!id) return; // Ensure id is available
        getEvent();
    }, [id]);

    const getEvent = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/event/${id}`
            );
            const data = response.data;
            console.log(data);
            let imgData = [];
            //'/api/v1/common/blogimages' is a common route to get the images from the server by passing the img name
            data.images.map(async (item) => {
                const img = await axios.get(
                    `${process.env.REACT_APP_BE_URI}/api/v1/common/blogimages/${item}`,
                    {
                        responseType: "blob",
                    }
                );
                setContent((prev) => ({
                    ...prev,
                    image: [...prev.images, URL.createObjectURL(img.data)],
                }));
                imgData.push(URL.createObjectURL(img.data));
            });
            setContent({
                title: data.title,
                category: data.category,
                images: imgData, // Ensure this is returned by your API
                slideCaptions: data.slideCaptions,
                description: data.content,
                mode: data.mode,
                link: data.link,
                location: data.location,
                date: data.date,
                time: data.time,
                postDate: data.createdAt,
                author: data.author,
                date: data.date,
            });
            setLoading(false); // Set loading to false once data is fetched
        } catch (err) {
            console.log(err);
            toast.error(err.response?.data?.message || "Failed to load data");
            setLoading(false); // Ensure loading is set to false even on error
        }
    };

    if (loading) {
        return <LoaderAnimation />; // Show a loading message while fetching data
    }
    console.log("this is content: ", content);
    return (
        <Container size="lg" my="md">
            <Card padding="lg">
                <Stack spacing="md">
                    <div className="">
                        <Title
                            style={{ textTransform: "capitalize" }}
                            order={2}
                        >
                            {content.title}
                        </Title>
                        {/* <Chip variant="filled" defaultChecked icon={<IconCategory size={15} />}  ml={"auto"}>{category}</Chip> */}
                        <div style={{ marginTop: "15px" }}>
                            <Badge
                                size="lg"
                                mr={5}
                                variant="gradient"
                                gradient={{
                                    from: "blue",
                                    to: "green",
                                    deg: 90,
                                }}
                                leftSection={<IconCategory size={15} />}
                            >
                                {content.category}
                            </Badge>
                            <Badge
                                size="lg"
                                mx={5}
                                style={{ textTransform: "capitalize" }}
                                variant="gradient"
                                gradient={{
                                    from: "blue",
                                    to: "green",
                                    deg: 90,
                                }}
                                leftSection={<IconUser size={15} />}
                            >
                                {content.author}
                            </Badge>
                        </div>
                    </div>
                    {/* IMAGE SLIDES SECTION START*/}
                    {content.images?.length > 0 ? (
                        <div style={{ width: "100%", marginTop: "10px" }}>
                            <Carousel>
                                {content.images?.map((item, index) => (
                                    <div key={index}>
                                        <img
                                            style={{ width: "100%" }}
                                            src={item}
                                        />
                                        <p>{content.slideCaptions[index]}</p>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    ) : null}
                    {/* IMAGE SLIDES SECTION END  */}
                    {/* EVENT DETAIL SECTION START HERE  */}
                    <div className="grid grid-cols-3">
                        <Badge
                            size="lg"
                            mx={5}
                            mb={5}
                            style={{ textTransform: "capitalize" }}
                            variant="gradient"
                            gradient={{ from: "blue", to: "green", deg: 90 }}
                            leftSection={<IconUser size={15} />}
                        >
                            Event Mode: {content.mode}
                        </Badge>
                        <Badge
                            size="lg"
                            mx={5}
                            mb={5}
                            style={{ textTransform: "capitalize" }}
                            variant="gradient"
                            gradient={{ from: "blue", to: "green", deg: 90 }}
                            leftSection={<IconUser size={15} />}
                        >
                            Event{" "}
                            {content.mode === "Online" ? "Link" : "Location"}:{" "}
                            {content.link || content.location}
                        </Badge>
                        <Badge
                            size="lg"
                            mx={5}
                            mb={5}
                            style={{ textTransform: "capitalize" }}
                            variant="gradient"
                            gradient={{ from: "blue", to: "green", deg: 90 }}
                            leftSection={<IconUser size={15} />}
                        >
                            Event Date:{" "}
                            {moment(content.date).format("MMMM Do YYYY")}
                        </Badge>
                        <Badge
                            size="lg"
                            mx={5}
                            mb={5}
                            style={{ textTransform: "capitalize" }}
                            variant="gradient"
                            gradient={{ from: "blue", to: "green", deg: 90 }}
                            leftSection={<IconUser size={15} />}
                        >
                            Event Time:{" "}
                            {moment(content.time, "HH:mm").format("hh:mm A")}
                        </Badge>
                    </div>
                    {/* EVENT DETAIL SECTION END HERE  */}
                    <Text size="md">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: content.description,
                            }}
                        />
                    </Text>

                    <Badge
                        size="lg"
                        mx={5}
                        variant="gradient"
                        gradient={{ from: "blue", to: "green", deg: 90 }}
                        style={{ textTransform: "capitalize" }}
                        leftSection={<IconCalendarMonth size={15} />}
                    >
                        Posted At: {moment(content.postDate).format("MMMM Do YYYY")}
                    </Badge>
                </Stack>
            </Card>
        </Container>
    );
};

export default EventView;
