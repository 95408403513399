import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import ProfileBar from "../ProfileBar/ProfileBar";
import Footer from "../Navbar/Footer";
import { getLocalStorage, removeLocalStorage } from "../../Helper/LocalStorage";
import { AppShell, Burger, Group } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import axios from "axios";
import { getCookie } from "../../Helper/Cokkies";
import toast from "react-hot-toast";

function HomePage() {
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);
  const navigate = useNavigate();
  useEffect(() => {
    checkAccess();
  }, []);
  const checkAccess = async () => {
    if (!getLocalStorage("user")) {
      navigate("/login");
    }else{
      const store = getLocalStorage("user");
      if (
        !sessionStorage.getItem("subsValidate") &&
        JSON.parse(sessionStorage.getItem("subsValidate")) != 1
      ) {
        axios
          .get(
            `${process.env.REACT_APP_BE_URI}/api/v1/common/verifysubs/${store.type}/${store.email}`
          )
          .then((res) => {
            if (!res.data.active) {
              toast.error("Your subscription is expired, please renew");
              removeLocalStorage("user");
              navigate("/login");
            }
            if(res.data.renew){
              toast.success("login for the new subscription");
              removeLocalStorage("user");
              navigate("/login");
            }
            sessionStorage.setItem("subsValidate", JSON.stringify(1));
          })
          .catch((err) => {
            console.log(err);
            toast.error(
              `Some error occured while validating: ${err.response.data.message}`
            );
          });
      }
    }
    // if (!getCookie("token")) {
    //   navigate("/login");
    // }
    // try {
    //   const res = await axios.post(
    //     `${process.env.REACT_APP_BE_URI}/api/v1/common/verifytoken`,
    //     {
    //       token: getCookie("token"),
    //     }
    //   );
    //   if (!res.data.active) {
    //     navigate("/login");
    //   }
    // } catch (error) {
    //   if (error.response.data.message.includes("JsonWebTokenError")) {
    //     toast.error("Session expired, please login again");
    //   }
    // }
  };

  const toggles = () => {
    return (
      <Group h="100%" px="md">
        <Burger
          opened={mobileOpened}
          onClick={toggleMobile}
          hiddenFrom="sm"
          size="sm"
        />
        <Burger
          opened={desktopOpened}
          onClick={toggleDesktop}
          visibleFrom="sm"
          size="sm"
        />
      </Group>
    );
  };

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: 250,
        breakpoint: "sm",
        collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
      }}
      // aside={{
      //   width: 275,
      //   breakpoint: "sm",
      //   collapsed: { mobile: true, desktop: false },
      // }}
      // bg={"var(--mantine-color-gray-1"}
    >
      <AppShell.Header>
        <Navbar toggles={toggles} />
      </AppShell.Header>
      <AppShell.Navbar>
        <Sidebar />
      </AppShell.Navbar>
      <AppShell.Main>
        <Outlet />
        <Footer />
      </AppShell.Main>
      {/* <AppShell.Aside>
        <ProfileBar />
      </AppShell.Aside> */}
    </AppShell>
  );
}
export default HomePage;
