import {
    Card,
    Text,
    Group,
    Button,
    Grid,
    Image,
    Switch,
    Badge,
    Box,
    Stack,
    CloseButton,
    Input,
    Pagination,
    Select,
    Avatar,
} from "@mantine/core";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import classes from "./Directory.module.css";
import { getLocalStorage, setLocalStorage } from "../../Helper/LocalStorage";
import { useEffect, useState } from "react";
import { useMantineTheme } from "@mantine/core";
import img from "./assets/profile.png";
import "@mantine/carousel/styles.css";
import axios from "axios";
import toast from "react-hot-toast";
import { useMediaQuery } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { blobToBase64 } from "../../Helper/BlobToBase64";

const Directory = () => {
    const navigate = useNavigate();
    const [newUser, setNewUser] = useState(true);
    const [nameValue, setNameValue] = useState("");
    const theme = useMantineTheme();
    // const members = [1, 1];
    const membersAll = [0, 1, 1, 0, 0, 1, 1, 0, 1];
    const [type, setType] = useState("All");
    const [course, setCourse] = useState("");
    const [organization, setOrganization] = useState("");
    const [profession, setProfession] = useState("");
    const [country, setCountry] = useState("");
    const [members, setMembers] = useState([]);
    const [profile, setProfile] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
    useEffect(() => {
        return () => {
            if (false) {
                setNewUser(false);
            }
            document.title = "Membership Directory";
            getAllMembers();
        };
    }, [page]);

    const getAllMembers = async () => {
        await axios
            .post(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/membersdirectory`,
                {
                    type: "All",
                    page: page,
                    limit: 10,
                    // course: course,
                    // organization: organization,
                    // profession: profession,
                    // country: country,
                }
            )
            .then((res) => {
                setMembers(res.data.data);
                setTotalPages(res.data.totalPages);
                getMemberProfilePic(res.data.data);

                console.log(res.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                console.log(err);
            });
        console.log(members);
    };
    const getMemberProfilePic = async (item) => {
        item.map((data) => {
            if (data?.profile) {
                axios
                    .get(
                        `${process.env.REACT_APP_BE_URI}/api/v1/common/getprofilepic/${data?.profile}`,
                        { responseType: "blob" }
                    )
                    .then((res) => {
                        const url = URL.createObjectURL(res.data);
                        setMembers((prev) => {
                            return prev.map((member) => {
                                if (member?._id === data?._id) {
                                    return { ...member, profile: url };
                                }
                                return member;
                            });
                        });
                    })
                    .catch((err) => {
                        toast.error(
                            err.response.data.message || "Failed to load image"
                        );
                        console.log(err);
                    });
            }
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        axios
            .post(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/membersdirectory`,
                {
                    type: type,
                    course: course,
                    organization: organization,
                    profession: profession,
                    country: country,
                    name: nameValue,
                }
            )
            .then((res) => {
                setMembers(res.data.data);
                getMemberProfilePic(res.data.data);
                console.log(res.data);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                console.log(err);
            });
    };
    const handleViewMember = async (member, profile) => {
        setLocalStorage("member", member);
        navigate(`/view_member`);
    };
    const searchStyles = {
        input: {
            background: "var(--mantine-color-gray-2)",
        },
        placeholder: {
            color: "var(--mantine-color-gray-4)",
        },
    };

    return (
        <Box p={isMobile ? "20px" : "40px"}>
            <Text
                fz={isMobile ? 30 : 50}
                fw={700}
                variant="gradient"
                gradient={{
                    from: theme.colors.secondary[0],
                    to: theme.colors.primary[0],
                    deg: 95,
                }}
                display={"inline"}
            >
                Membership Directory
            </Text>
            <br />
            {/* <Text
                fz={isMobile ? 20 : 35}
                fw={700}
                pt="lg"
                variant="gradient"
                gradient={{
                    from: theme.colors.secondary[0],
                    to: theme.colors.primary[0],
                    deg: 95,
                }}
                display={"inline"}
            >
                New Members
            </Text>
            <Stack pt={"lg"} pb={"xl"}>
                {members.map((member, i) => (
                    <Card key={i} radius={"xl"} shadow="xl">
                        <Grid>
                            <Grid.Col span={isMobile ? 12 : 4}>
                                <Image
                                    src={profile[i] || img}
                                    radius={"xl"}
                                    p={"sm"}
                                    h={isMobile ? "auto" : "100%"}
                                />
                            </Grid.Col>
                            <Grid.Col
                                span={isMobile ? 12 : 8}
                                py={"lg"}
                                c="#2C3238"
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Text fz="lg" fw={500}>
                                        {member.firstName} {member.lastName}
                                    </Text>
                                    <Badge
                                        fz="md"
                                        fw={500}
                                        py={"sm"}
                                        bg="green"
                                    >
                                        {member.type}
                                    </Badge>
                                </div>
                                <Text fz="sm" fw={500} c={"dimmed"} pb={"sm"}>
                                    {member.email}
                                </Text>
                                <Text fz="sm" fw={400}>
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry...
                                </Text>
                            </Grid.Col>
                        </Grid>
                    </Card>
                ))}
            </Stack> */}

            <Text
                fz={isMobile ? 20 : 35}
                fw={700}
                variant="gradient"
                gradient={{
                    from: theme.colors.secondary[0],
                    to: theme.colors.primary[0],
                    deg: 95,
                }}
                display={"inline"}
            >
                Member Search
            </Text>

            <div className={classes.wideFlex}>
                <Stack
                    spacing="sm"
                    style={{
                        display: isMobile ? "block" : "flex",
                        gap: "10px",
                        marginTop: "10px",
                        flexDirection: isMobile ? "column" : "row",
                    }}
                >
                    <Input
                        placeholder="Search"
                        leftSection={
                            <IconSearch size={25} style={{ color: "black" }} />
                        }
                        value={nameValue}
                        onChange={(event) =>
                            setNameValue(event.currentTarget.value)
                        }
                        styles={searchStyles}
                        my={isMobile ? "10px" : "0px"}
                        rightSection={
                            <CloseButton
                                aria-label="Clear input"
                                onClick={() => setNameValue("")}
                                style={{
                                    display: nameValue ? undefined : "none",
                                }}
                            />
                        }
                    />
                    <Select
                        placeholder="Pick member type"
                        data={["All", "Professional", "Student"]}
                        value={type}
                        onChange={(value) => setType(value)}
                        w={isMobile ? "100%" : "180px"}
                        styles={searchStyles}
                    />
                </Stack>
            </div>

            <form
                style={{
                    display: "flex",
                    gap: "10px",
                    marginTop: "10px",
                    flexDirection: isMobile ? "column" : "row",
                }}
                onSubmit={handleSubmit}
            >
                {type === "Professional" && (
                    <>
                        <Select
                            placeholder="Profession"
                            rightSection={<IconChevronDown />}
                            data={[
                                "Dietician",
                                "Nutritionist",
                                "Doctor",
                                "Researcher",
                                "Professor",
                                "Others",
                            ]}
                            value={profession}
                            onChange={(value) => setProfession(value)}
                            w={isMobile ? "100%" : "150px"}
                            styles={searchStyles}
                        />
                        <Select
                            placeholder="Country"
                            rightSection={<IconChevronDown />}
                            data={[
                                "Australia",
                                "India",
                                "Indonesia",
                                "Japan",
                                "United Kingdom",
                                "United States",
                            ]}
                            value={country}
                            onChange={(value) => setCountry(value)}
                            w={isMobile ? "100%" : "150px"}
                            styles={searchStyles}
                        />
                    </>
                )}
                {type === "Student" && (
                    <>
                        <Select
                            placeholder="Course"
                            rightSection={<IconChevronDown />}
                            data={[
                                "Accountancy",
                                "Business Management",
                                "Civil Engineering",
                                "Computer Science",
                                "Dentistry",
                                "Electrical Engineering",
                                "Medicine",
                                "Others",
                            ]}
                            value={course}
                            onChange={(value) => setCourse(value)}
                            w={isMobile ? "100%" : "150px"}
                            styles={searchStyles}
                        />
                        <Select
                            placeholder="Country"
                            rightSection={<IconChevronDown />}
                            data={[
                                "Australia",
                                "India",
                                "Indonesia",
                                "Japan",
                                "United Kingdom",
                                "United States",
                            ]}
                            value={country}
                            onChange={(value) => setCountry(value)}
                            w={isMobile ? "100%" : "150px"}
                            styles={searchStyles}
                        />
                    </>
                )}
                {type === "All" && (
                    <Select
                        placeholder="Country"
                        rightSection={<IconChevronDown />}
                        data={[
                            "Australia",
                            "India",
                            "Indonesia",
                            "Japan",
                            "United Kingdom",
                            "United States",
                        ]}
                        value={country}
                        onChange={(value) => setCountry(value)}
                        w={isMobile ? "100%" : "150px"}
                        styles={searchStyles}
                    />
                )}
                <Button
                    type="submit"
                    style={{ width: isMobile ? "100%" : "auto" }}
                >
                    Search
                </Button>
            </form>

            <Grid py="lg">
                {members.map((member) => (
                    <Grid.Col
                        onClick={() => handleViewMember(member)}
                        className={classes.member_card}
                        key={member._id}
                        span={isMobile ? 12 : 4}
                    >
                        <Card radius="xl" shadow="xl" c="#25313C" p="md">
                            <Avatar
                                size={isMobile ? 100 : 200}
                                mx={"auto"}
                                src={member?.profile}
                            />
                            <div className={classes.contents}>
                                <Text
                                    fz="lg"
                                    fw={500}
                                    style={{ textTransform: "capitalize" }}
                                >
                                    {member.firstName} {member.lastName}
                                </Text>
                                <Text fz="sm" fw={500} c={"dimmed"}>
                                    {member.email}
                                </Text>
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "10px",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Text fz="sm" fw={600}>
                                        Nutritionist
                                    </Text>
                                    <Text
                                        fz="sm"
                                        fw={600}
                                        style={{ textTransform: "capitalize" }}
                                    >
                                        {member.type}
                                    </Text>
                                </div>
                            </div>
                        </Card>
                    </Grid.Col>
                ))}
            </Grid>

            <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                    total={totalPages}
                    onChange={(e) => setPage(e)}
                    autoContrast
                    color="lime.5"
                    mx="auto"
                    pt="md"
                />
            </div>
        </Box>
    );
};
export default Directory;
