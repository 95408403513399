import { Badge, Container, Paper, Title } from "@mantine/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";

export default function VlogView() {
    const [data, setData] = useState([]);
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");

    useEffect(() => {
        if (!id) return; // Ensure id is available
        getVlog();
    }, [id]);

    const getVlog = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/getvlog/${id}`
            );
            setData(res.data);
            console.log(res.data);
        } catch (err) {
            console.log(err);
            toast.error(err.response.data.message || "Failed to load vlog");
        }
    };

    return (
        <Container size="md" my="lg">
            <Paper shadow="xl" p="lg" radius="md">
                {/* Video Title */}
                <Title size={"h3"} mb="md">
                    {data.title}
                </Title>
                <Badge color="pink">{data.category}</Badge>

                {/* Video Embed */}
                {data.url && (
                    <div
                        className="video-wrapper bg-gray-100 shadow-xl py-2 px-16 mx-auto rounded-xl"
                        style={{
                            marginTop: "20px",
                            width: "100%",
                            position: "relative",
                            paddingBottom: "56.25%", // Aspect ratio of 16:9
                            height: 0,
                        }}
                        dangerouslySetInnerHTML={{ __html: data.url }}
                    ></div>
                )}
            </Paper>
        </Container>
    );
}
