import {
    Card,
    Text,
    Grid,
    Image,
    Box,
    Stack,
    Badge,
    Button,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import classes from "./Dashboard.module.css";
import { getLocalStorage } from "../../Helper/LocalStorage";
import { useEffect, useState } from "react";
import { useMantineTheme } from "@mantine/core";
import img from "./img.jpg";
import "@mantine/carousel/styles.css";
import { useMediaQuery } from "@mantine/hooks";
import axios from "axios";
import toast from "react-hot-toast";
import { truncateTo100Words } from "../../Helper/TruncateText";
import { IconCalendarMonth, IconCategory, IconUser } from "@tabler/icons-react";
import moment from "moment";

const Dashboard = () => {
    const [newUser, setNewUser] = useState(true);
    const [data, setData] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [images, setImages] = useState({});
    const [eventImages, setEventImages] = useState({});
    const theme = useMantineTheme();
    const isMobile = useMediaQuery("(max-width: 768px)");
    const blogs = [1, 1];
    const jobs = [0, 1, 1, 0, 1];

    useEffect(() => {
        return () => {
            if (false) {
                setNewUser(false);
            }
            document.title = "Dashboard";
            getBlogs();
            getVlogs();
        };
    }, []);
    const getBlogs = async () => {
        axios
            .get(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/getallblogs/dash`
            )
            .then((res) => {
                setData(res.data);
                console.log(res.data);
                res.data.map((blog) => {
                    console.log(blog.images[0]);
                    axios
                        .get(
                            `${process.env.REACT_APP_BE_URI}/api/v1/common/blogimages/${blog.images[0]}`,
                            {
                                responseType: "blob",
                            }
                        )
                        .then((res) => {
                            const img = URL.createObjectURL(res.data);
                            setImages((prev) => ({ ...prev, [blog._id]: img }));
                        })
                        .catch((err) => {
                            console.log(err);
                            toast.error(
                                err.response?.data?.message ||
                                    "Failed to load images"
                            );
                        });
                });
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getVlogs = async () => {
        axios
            .get(`${process.env.REACT_APP_BE_URI}/api/v1/common/events/1`)
            .then((res) => {
                setEventData(res.data);
                res.data.map((item) => {
                    // getting the images would have the common path from BE don't get confused by blogImages
                    axios
                        .get(
                            `${process.env.REACT_APP_BE_URI}/api/v1/common/blogimages/${item.images[0]}`,
                            {
                                responseType: "blob",
                            }
                        )
                        .then((res) => {
                            const img = URL.createObjectURL(res.data);
                            setEventImages((prev) => ({
                                ...prev,
                                [item._id]: img,
                            }));
                        })
                        .catch((err) => {
                            console.log(err);
                            toast.error(
                                err.response?.data?.message ||
                                    "Failed to load images"
                            );
                        });
                });
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const carouselSlides = jobs.map((item) => {
        return (
            <Carousel.Slide>
                <Card shadow="lg" radius="lg" className={classes.jobcard}>
                    {item === 1 && <Image src={img} radius={"lg"}></Image>}
                    <div>
                        <Text fw={600}>
                            Weight training and linked problems
                        </Text>
                        <Text>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Modi repudiandae laborum incidunt delectus et
                            earum ea nemo natus minus!
                        </Text>
                    </div>
                </Card>
            </Carousel.Slide>
        );
    });

    return (
        <Box p={"40px"}>
            <Text
                fz={50}
                fw={700}
                variant="gradient"
                gradient={{
                    from: theme.colors.secondary[0],
                    to: theme.colors.primary[0],
                    deg: 95,
                }}
                display={"inline"}
            >
                Recent Articles
            </Text>
            <Text fw={500} c={"dimmed"} pb={"lg"}>
                Our latest updates and blogs on nutrition, care and much more.{" "}
            </Text>
            <Text
                fz={35}
                fw={700}
                variant="gradient"
                gradient={{
                    from: theme.colors.secondary[0],
                    to: theme.colors.primary[0],
                    deg: 95,
                }}
                display={"inline"}
            >
                Blogs
            </Text>
            <Stack pt={"lg"} pb={"xl"}>
                {data.map((blog) => {
                    return (
                        <Card radius="xl" shadow="xl">
                            <Grid gutter="sm">
                                <Grid.Col my={"auto"} span={isMobile ? 12 : 6}>
                                    <Image
                                        src={images[blog?._id]}
                                        radius="xl"
                                        p="sm"
                                    />
                                </Grid.Col>
                                <Grid.Col span={isMobile ? 12 : 6} py="lg">
                                    <Text
                                        style={{ textTransform: "capitalize" }}
                                        fz="lg"
                                        fw={500}
                                    >
                                        {blog?.title}
                                    </Text>
                                    <hr align="left" className={classes.line} />
                                    <div style={{ display: "flex" }}>
                                        <Badge
                                            size="lg"
                                            leftSection={
                                                <IconCategory size={18} />
                                            }
                                            mx={5}
                                            color="pink"
                                        >
                                            {blog.category}
                                        </Badge>
                                        <Badge
                                            size="lg"
                                            leftSection={
                                                <IconCalendarMonth size={18} />
                                            }
                                            mx={5}
                                            color="purple"
                                        >
                                            {moment(blog.date).format(
                                                "MMMM Do YYYY"
                                            )}
                                        </Badge>
                                    </div>
                                    <Text fz="sm" fw={400}>
                                        <div
                                            style={{
                                                marginBottom: "2.5rem", // equivalent to mb-10
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: truncateTo100Words(
                                                    blog.content
                                                ),
                                            }}
                                        />
                                    </Text>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Button 
                                            onClick={() =>
                                                window.open(
                                                    `/blog_view?id=${blog._id}`,
                                                    "_blank"
                                                )
                                            }
                                            style={{
                                                position: "absolute",
                                                bottom: `${
                                                    isMobile ? "20px" : "30px"
                                                }`,
                                                width: "40%",
                                                margin: "auto",
                                            }}
                                            autoContrast
                                            color="#A3E5E4"
                                            mt="md"
                                            radius="md"
                                        >
                                            Read
                                        </Button>
                                    </div>
                                </Grid.Col>
                            </Grid>
                        </Card>
                    );
                })}
            </Stack>

            {/* UPCOMING EVENTS  */}
            <Text
                fz={35}
                fw={700}
                variant="gradient"
                gradient={{
                    from: theme.colors.secondary[0],
                    to: theme.colors.primary[0],
                    deg: 95,
                }}
                display={"inline"}
            >
                Upcoming Events
            </Text>
            <Stack pt={"lg"} pb={"xl"} className={classes.alternate}>
                {eventData.map((item) => {
                    return (
                        <Card
                            onClick={() =>
                                window.open(
                                    `/event_view?id=${item._id}`,
                                    "_blank"
                                )
                            }
                            style={{ cursor: "pointer" }}
                            radius="xl"
                            shadow="xl"
                        >
                            <Grid gutter="sm">
                                <Grid.Col span={isMobile ? 12 : 6}>
                                    <Image
                                        src={eventImages[item?._id]}
                                        radius="xl"
                                        p="sm"
                                    />
                                </Grid.Col>
                                <Grid.Col span={isMobile ? 12 : 6} py="lg">
                                    <Text fz="lg" fw={500}>
                                        {item?.title}
                                    </Text>
                                    <hr align="left" className={classes.line} />
                                    <Text fz="sm" tt={"capitalize"} fw={500} pb="lg">
                                        {moment(item.date).format(
                                            "MMMM Do YYYY"
                                        )}
                                        <br />
                                        {moment(item.time, "HH:mm").format(
                                            "hh:mm A"
                                        )}
                                        <br />
                                        {item.location || item.link}
                                        <br />
                                        {item.category}
                                    </Text>
                                    <Text fz="sm" fw={400}>
                                        {truncateTo100Words(item.content)}
                                    </Text>
                                </Grid.Col>
                            </Grid>
                        </Card>
                    );
                })}
            </Stack>

            {/* PROFESSIONAL OPPROTUNITIES  */}
            <Text
                fz={35}
                fw={700}
                variant="gradient"
                gradient={{
                    from: theme.colors.secondary[0],
                    to: theme.colors.primary[0],
                    deg: 95,
                }}
                display={"inline"}
            >
                Professional Opportunities
            </Text>
            <Carousel
                withIndicators
                slideSize={{ base: "100%", sm: "50%", md: "33.333333%" }}
                slideGap={{ base: 0, sm: "md" }}
                loop
                align="start"
            >
                {carouselSlides}
            </Carousel>
        </Box>
    );
};
export default Dashboard;
